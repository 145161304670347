import { Icon } from '@/components/atoms/Icons';
import { useRouter } from 'next/router';
import { useTypesJsonData } from '@/hooks/useTypesJsonData';
import navbarData from '@/public/data/data.json';

interface FooterProps {
  className?: string;
}

export default function FooterSmallScreen(props: FooterProps) {
  const { className } = props;
  const router = useRouter();

  const data: useTypesJsonData = navbarData;
  const dir = data.footer.direction;
  const options = data.footer.options;
  const icons = data.footer.icons;
  const polices = data.footer.polices;
  const copyright = data.footer.copyright;

  return (
    <footer className={`w-full text-white   ${className}`}>
      <div className="p-7 justify-between items-center bg-primary-900">
        <div className="flex items-center">
          <Icon name="digitoLetterWhite" width="12rem" height="4rem" />
        </div>
        <span
          className="
               nunito leading-7 opacity-20 text-[1rem] max-w-[15rem]"
        >
          {dir.dir}
        </span>
        <div>
          <ul className="items-center  mt-8  transition duration-300 ease-in-out">
            {options.map((option) => (
              <li key={option.key} className="mr-6">
                <span
                  key={option.key}
                  className="font-nunito text-[1rem] mt-5 opacity-20"
                >
                  {option.title}
                </span>
                <div key={option.key} className="mb-6">
                  {option.option.map((opt) => (
                    <>
                      <span
                        key={opt.label}
                        onClick={() => router.push('validate-doc')}
                        className="flex mb-4 mt-2 cursor-pointer font-nunito text-[1rem]"
                      >
                        {opt.label}
                      </span>
                    </>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <hr className="border-primary-300 opacity-30" />
      <div className="flex-col items-center justify-between bg-primary-750">
        <div className="flex items-center justify-center">
          {icons.map((icon) => (
            <Icon
              key={icon.key}
              onClick={() => window.open(icon.redirectUrl)}
              name={icon.name as 'facebook'}
              width="3rem"
              className="cursor-pointer mt-7"
            />
          ))}
        </div>
        <div>
          <ul className="text-center">
            {polices.map((police) => (
              <li key={police.key} className="cursor-pointer my-5">
                {police.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="opacity-20 text-center pb-4">{copyright.text}</div>
        <hr className="border-primary-300 opacity-20 pb-4" />
      </div>
    </footer>
  );
}
