import { useState, useEffect } from 'react';

export const useResponsive = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isMobileLogin, setIsMobileLogin] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [isMobileFooter, setIsMobileFooter] = useState<boolean>(false);
    const [isMobileAdmin, setIsMobileAdmin] = useState<boolean>(false);
    const [isMobileBeforeSign, setIsMobileBeforeSign] = useState<boolean>(false);
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
    const [sizeActual, setSizeActual] = useState<number>(0)
    
    const handleResize = () => {
        setIsMobile(window.innerWidth < 840);
        setIsMobileFooter(window.innerWidth < 700);
        setIsMobileBeforeSign(window.innerWidth < 755);
        setIsMobileAdmin(window.innerWidth < 818);
        setIsTablet(window.innerWidth < 1070);
        setIsMobileLogin(window.innerWidth < 898)
        setSizeActual(window.innerWidth)
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isMobile,  isShowMenu, isMobileAdmin,  setIsShowMenu, isMobileLogin, isTablet, isMobileFooter, isMobileBeforeSign, sizeActual };
};