import { Icon } from '@/components/atoms/Icons';
import { useRouter } from 'next/router';
import { useTypesJsonData } from '@/hooks/useTypesJsonData';
import navbarData from '@/public/data/data.json';

interface FooterProps {
  className?: string;
  isTablet: boolean;
}

export default function FooterLargeScreen(props: FooterProps) {
  const { className, isTablet } = props;
  const router = useRouter();

  const data: useTypesJsonData = navbarData;
  const dir = data.footer.direction;
  const options = data.footer.options;
  const icons = data.footer.icons;
  const polices = data.footer.polices;
  const copyright = data.footer.copyright;

  return (
    <footer className={'w-full text-white'}>
      <div className="flex p-7 justify-between items-center bg-primary-900">
        <div className="flex items-center">
          <div>
            <Icon name="digitoLetterWhite" width="12rem" height="4rem" />
          </div>
        </div>
        <span
          className="
               font-nunito leading-7 opacity-20  text-[1rem] max-w-[20rem]"
        >
          {dir.dir}
        </span>
        <div>
          <ul
            className={`${isTablet ? 'ml-[2rem]' : 'ml-5'} flex flex-wrap gap-3 mr-3 items-center transition duration-300 ease-in-out`}
          >
            {options.map((option, index) => (
              <li key={option.key + '-' + index} className="mr-4">
                <span className="font-nunito text-[1rem] opacity-20">
                  {option.title}
                </span>
                <div className="flex items-center gap-2">
                  {option.option.map((opt, index) => (
                    <div key={opt.label} className="flex items-center">
                      <span
                        onClick={() => router.push('validate-doc')}
                        className="cursor-pointer font-nunito text-[1rem]"
                      >
                        {opt.label}
                      </span>
                      {index + 1 < option.option.length && (
                        <span className="text-[1rem] ml-[0.6rem] opacity-20">
                          •
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <hr className="h-0 border-primary-140 opacity-80" />
      <div className="flex h-[4.69rem] items-center justify-between bg-primary-750">
        <div className="opacity-20 ml-7">{copyright.text}</div>

        <div className="flex items-center">
          {icons.map((icon) => (
            <Icon
              key={icon.key}
              onClick={() => window.open(icon.redirectUrl)}
              name={icon.name as 'facebook'}
              width="3rem"
              className="cursor-pointer"
            />
          ))}
        </div>
        <div>
          <ul className="flex gap-4 items-center mr-7">
            {polices.map((police, index) => (
              <li key={police.key}>
                <div className="cursor-pointer">{police.label}</div>
                {index + 1 < polices.length && (
                  <span className="text-[1rem] opacity-20">•</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}
