import Facebook from '@/public/img/svg/socialMedia/facebook-icon.svg';
import Instagram from '@/public/img/svg/socialMedia/instagram-icon.svg';
import Twitter from '@/public/img/svg/socialMedia/twitter-icon.svg';
import FacebookWhite from '@/public/img/svg/socialMedia/facebook-white.svg';
import InstagramWhite from '@/public/img/svg/socialMedia/instagram-white.svg';
import TwitterWhite from '@/public/img/svg/socialMedia/twitter-white.svg';
import Digito from '@/public/img/svg/digito-icon.svg';
import DigitoLetter from '@/public/img/svg/digito-letter-icon.svg';
import DigitoLetterWhite from '@/public/img/svg/digito-letter-white.svg';
import ArrowSimple from '@/public/img/svg/arrow-simple.svg';
import Close from '@/public/img/svg/close-icon.svg';
import Menu from '@/public/img/svg/menu-icon.svg';
import PersonaCardID from '@/public/img/svg/persona/card-id.svg';
import PersonaFaceCard from '@/public/img/svg/persona/face-card.svg';
import PersonaSquareFace from '@/public/img/svg/persona/square-face.svg';
import PersonaFingerprint from '@/public/img/svg/persona/fingerprint.svg';
import FourLineGray from '@/public/img/svg/four-line-decoration-gray.svg';
import PointDecorationYellow from '@/public/img/svg/point-decoration-yellow.svg';
import PdfRed from '@/public/img/svg/pdf-red.svg';
import Avatar from '@/public/img/svg/avatar.svg';
import Return from '@/public/img/svg/return.svg';
import MultiPoint from '@/public/img/svg/multi-point-purple.svg';
import ArrowLine from '@/public/img/svg/arrow-line.svg';
import CheckWhite from '@/public/img/svg/check-xl-white.svg';
import CheckDone from '@/public/img/svg/check-done.svg';
import Certificate from '@/public/img/svg/certificate-icon.svg';
import CheckSimple from '@/public/img/svg/check-simple.svg';
import Alert from '@/public/img/svg/alert-icon.svg';
import Error from '@/public/img/svg/error-icon.svg';
import Bell from '@/public/img/svg/profile/bell-icon.svg';
import Shield from '@/public/img/svg/profile/shield-icon.svg';
import Clock from '@/public/img/svg/profile/clock-icon.svg';
import Signature from '@/public/img/svg/profile/signature-icon.svg';
import People from '@/public/img/svg/profile/people-icon.svg';
import Upload from '@/public/img/svg/profile/upload-icon.svg';
import EyesOpen from '@/public/img/svg/eyes-open.svg';
import EyesClose from '@/public/img/svg/eyes-close.svg';
import DefaultImage from '@/public/img/svg/default-image.svg';

interface IconsProps extends React.SVGProps<SVGSVGElement> {
  name:
    | 'personaCardId'
    | 'bell'
    | 'shield'
    | 'clock'
    | 'signature'
    | 'people'
    | 'upload'
    | 'eyesOpen'
    | 'eyesClose'
    | 'personaFaceCard'
    | 'personaSquareFace'
    | 'personaFingerprint'
    | 'digito'
    | 'digitoLetter'
    | 'digitoLetterWhite'
    | 'instagram'
    | 'facebook'
    | 'checkSimple'
    | 'twitter'
    | 'instagramWhite'
    | 'facebookWhite'
    | 'twitterWhite'
    | 'arrowSimple'
    | 'menu'
    | 'pointDecorationYellow'
    | 'pdfRed'
    | 'fourLineGray'
    | 'avatar'
    | 'checkDone'
    | 'return'
    | 'multiPoint'
    | 'certificate'
    | 'defaultImage'
    | 'alert'
    | 'arrowLine'
    | 'checkWhite'
    | 'error'
    | 'close';
}

export const Icon = (props: IconsProps) => {
  const { name, ...restProps } = props;

  const componentMap: Record<
    IconsProps['name'],
    React.FC<React.SVGProps<SVGSVGElement>>
  > = {
    personaCardId: PersonaCardID,
    personaFaceCard: PersonaFaceCard,
    personaSquareFace: PersonaSquareFace,
    personaFingerprint: PersonaFingerprint,
    digito: Digito,
    bell: Bell,
    shield: Shield,
    clock: Clock,
    signature: Signature,
    defaultImage: DefaultImage,
    people: People,
    upload: Upload,
    eyesOpen: EyesOpen,
    eyesClose: EyesClose,
    menu: Menu,
    alert: Alert,
    error: Error,
    digitoLetter: DigitoLetter,
    instagram: Instagram,
    twitter: Twitter,
    facebook: Facebook,
    digitoLetterWhite: DigitoLetterWhite,
    instagramWhite: InstagramWhite,
    twitterWhite: TwitterWhite,
    facebookWhite: FacebookWhite,
    arrowSimple: ArrowSimple,
    pointDecorationYellow: PointDecorationYellow,
    pdfRed: PdfRed,
    certificate: Certificate,
    fourLineGray: FourLineGray,
    checkDone: CheckDone,
    checkSimple: CheckSimple,
    avatar: Avatar,
    return: Return,
    multiPoint: MultiPoint,
    arrowLine: ArrowLine,
    checkWhite: CheckWhite,
    close: Close,
  };

  const IconComponent = componentMap[name];

  return <IconComponent {...restProps} />;
};
