import { useUser } from '@auth0/nextjs-auth0/client';

export const NavbarBackGround = () => {
  const user = useUser();
  return (
    <div
      className={`bg-primary-550 h-[18rem] w-[82%] mx-auto top-0 rounded-b-[1.25rem] ${user.user ? 'max-tablet-md:w-full' : 'max-tablet-md:w-full'}`}
    />
  );
};
