import { Icon } from '@/components/atoms/Icons';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import navbarData from '@/public/data/data.json';
import { useTypesJsonData  } from '@/hooks/useTypesJsonData';
import { useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useAuthNavigation } from '@/hooks/useAuthNavigation';

export const NavbarSmallScreenLogin = () => {
  const [isClose, setIsClose] = useState(true);
  const user = useUser();
  const router = useRouter();
  const data: useTypesJsonData  = navbarData;
  const options = data.navbar.navbarOptionsLogin;
  const icons = data.navbar.navbarResponsive.icons || [];
  const polices = data.navbar.navbarResponsive.polices || [];
  const auth = data.navbar.navbarResponsive.buttons || [];
  const { handleLogoutNavigation } = useAuthNavigation();

  const handleNavigationClicked = useCallback(
    (redirectUri: string) => {
      router.push(redirectUri);
    },
    [router]
  );

  const handleOnKeyDowned = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        router.push('/none');
      }
    },
    [router]
  );

  const handleRouterOptions = (type: string, url?: string) => {
    if (type === 'logout') {
      handleLogoutNavigation();
    }
    if (type === 'validate') {
      window.open(url, '_blank');
    }
  };

  return (
    <div className="h-[4.80rem] bg-white w-full ">
      <div>
        <nav>
          <ul>
            <li className="flex justify-between items-center cursor-pointer  px-5 ">
              <Icon
                name="digitoLetter"
                className="translate-y-[0.5rem]"
                width="5.66rem"
                height="1.65rem"
              />

              <div className="flex items-center  mt-3 translate-x-4 gap-8">
                <Icon
                  name="avatar"
                  width="3rem"
                  height="3rem"
                  className="translate-x-6"
                />
                <Icon
                  onClick={() => setIsClose(!isClose)}
                  name="arrowSimple"
                  className={`${isClose ? 'rotate-90' : '-rotate-90'} mr-4  cursor-pointer transition-all duration-300 ease-in-out`}
                  width="1.20rem"
                  height="1.20rem"
                />
              </div>
            </li>
            <div
              className={` bg-white ${
                isClose
                  ? 'translate-x-[-60rem] opacity-0'
                  : 'opacity-100 translate-x-0 '
              } transition-all duration-200 ease-in-out mt-[1.1rem] pt-4`}
            >
              {options.map((opt, index) => {
                if (opt.isEnable) {
                  return (
                    <li
                      key={`navbar-options-${opt.id}`}
                      className="cursor-pointer"
                    >
                      <div className="font-sohne text-[1.13rem]">
                        {index === 0 && (
                          <div className="w-full text-center">
                            <button
                              className="text-primary-550 px-6"
                              type="button"
                              tabIndex={0}
                              aria-label="Ir a la página de destino"
                            >
                              {user?.user?.email ?? 'juan Perez'}
                            </button>
                            <hr className="border-t-1 border-gray border-dashed my-3 px-0" />
                          </div>
                        )}

                        <div className="flex items-center justify-between px-6">
                          <button
                            type="button"
                            tabIndex={0}
                            aria-label="Ir a la página de destino"
                            onKeyDown={handleOnKeyDowned}
                            onClick={() =>
                              handleRouterOptions(opt.key, opt.redirectUrl)
                            }
                          >
                            {opt.label}
                          </button>
                          <Icon
                            width="1.20rem"
                            height="1.20rem"
                            name="arrowSimple"
                          />
                        </div>
                      </div>
                      {index + 1 < options.length && (
                        <hr className="border-t-1 border-gray border-dashed my-3" />
                      )}
                    </li>
                  );
                }
                return null;
              })}
            </div>
          </ul>
        </nav>
      </div>

      <div
        className={`bg-white ${
          isClose
            ? 'translate-x-[-60rem] opacity-0'
            : 'opacity-100 translate-x-0'
        } transition-all duration-200 ease-in-out pb-10`}
      >
        <div className="flex items-center justify-center pt-5 gap-4">
          {icons.map((icon) => (
            <Icon
              onClick={() => window.open('https://www.instagram.com', '_blank')}
              name={icon.name as 'facebook'}
              className="cursor-pointer"
              key={icon.key}
            />
          ))}
        </div>

        <div className="flex-col font-nunito font-bold text-center mt-8 text-primary-500">
          <ul>
            {polices.map((police) => (
              <li key={police.key} className="mb-5 cursor-pointer">
                {police.label}
              </li>
            ))}
          </ul>
        </div>

        <hr className="border-t-1 border-gray  my-6 mb-12" />
        <div>
          <nav>
            <ul className="flex justify-center gap-10 items-center">
              {auth.map((au) => {
                if (au.isEnable) {
                  if (au.key !== 'logout' && user.user) {
                    return null;
                  }
                  return (
                    <li key={au.key} className="cursor-pointer">
                      <button
                        className="bg-primary-550 w-[11rem] h-[2.6rem] text-primary-550 rounded-full  border-opacity-5 text-white font-nunito font-bold text-[1rem]"
                        type="button"
                        tabIndex={0}
                        aria-label="Ir a la página de destino"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            router.push(au.redirectUrl);
                          }
                        }}
                        onClick={() =>
                          handleRouterOptions(au.key, au.redirectUrl)
                        }
                      >
                        {au.label}
                      </button>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </nav>
        </div>
      </div>

      <div
        onClick={() => setIsClose(!isClose)}
        className={`${
          isClose
            ? 'translate-x-[-60rem] opacity-0'
            : 'opacity-100 translate-x-0'
        } transition-all duration-200 ease-in-out bg-primary-550 w-full  h-screen opacity-80`}
      />
    </div>
  );
};
